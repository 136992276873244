import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar/Main.js"
import Footer from "../components/Footer/Main.js"
import axios from "axios";
import moment from 'moment';
import client from "../feathers.js"

var pattern = "0000";

const Summary = (props) => {
  const [ data, setData ] = useState({billingAddress: {}, invoiceData:{}})

  useEffect(() => {
    if(props.match.params.id){
      axios.get(`${client.io.io.uri}getSummary`,{
        params: {
          id: props.match.params.id
        },
      }).then(res => {
        setData(res.data[0])
      })
    }
  }, [props.match.params.id])

  return (  
    <>
      <NavBar />
      <main>
        <div
          style={{
            backgroundImage: 'url(//cdn.shopify.com/s/files/1/3012/8606/t/4/assets/collection_top.jpg?v=13539636231063041806)', 
            position:'relative', 
            marginBottom: 100,
            minHeight: 530
          }}
        >
          <div className="breadcrumbs">
            <div className="container" style={{paddingRight: 60, paddingLeft: 60}}>
              <h1>Summary</h1>
              <ul className="breadcrumb" style={{display: 'block'}}>
                <li><a href="/" style={{color:'#fff'}}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="/viewcart"><span>Summary</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container" style={{marginBottom:100}}>
          <section className="order_details section-margin--small">
            <div className="container">
              <h2 className="text-center mb-5">Thank You! Your order has been received.</h2>
              <div className="row mb-5">
                <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                  <div className="well">
                    <h3 className="billing-title">Order Info</h3>
                    <table className="order-rable">
                      <tbody><tr>
                          <td>Order number</td>
                          <td>: {data.invoiceData.invoicePrefix}
                            {(pattern + data.invoiceData.invoiceNumber).slice(-4)}
                          </td>
                        </tr>
                        <tr>
                          <td>Date</td>
                          <td>: {moment(data.createdAt).format("MMM Do YYYY")}</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>: {data.currency}{" "} 
                            {
                            data.currency === "USD"?
                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.totalPaidAmount)
                            :
                            new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(data.totalPaidAmount)
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Payment method</td>
                          <td>: {data.paymentMethod?(data.paymentMethod).toUpperCase():data.paymentMethod}</td>
                        </tr>
                        <tr>
                          <td>Tracking Num.</td>
                          <td>: 
                            <span className="font-weight-bold">
                              {data.invoiceData.trackingNumber?data.invoiceData.trackingNumber:'Tracking Number will be displayed once your item is shipped.'}
                            </span>
                          </td>
                        </tr>
                      </tbody></table>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                  <div className="well">
                    <h3 className="billing-title">Billing Address</h3>
                    <table className="order-rable">
                      <tbody><tr>
                          <td>Street</td>
                          <td>: {data?.billingAddress?.address_line_1}</td>
                        </tr>
                        <tr>
                          <td>Region</td>
                          <td>: {data?.billingAddress?.admin_area_1}</td>
                        </tr>
                        <tr>
                          <td>Country</td>
                          <td>: {data?.billingAddress?.country_code}</td>
                        </tr>
                        <tr>
                          <td>Postcode</td>
                          <td>: {data?.billingAddress?.postal_code}</td>
                        </tr>
                      </tbody></table>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                  <div className="well">
                    <h3 className="billing-title">Shipping Address</h3>
                    <table className="order-rable">
                      <tbody><tr>
                          <td>Street</td>
                          <td>: {data?.invoiceData?.address}</td>
                        </tr>
                        <tr>
                          <td>Region</td>
                          <td>: {data?.invoiceData?.state}</td>
                        </tr>
                        <tr>
                          <td>Country</td>
                          <td>: {data?.invoiceData?.country}</td>
                        </tr>
                        <tr>
                          <td>Postcode</td>
                          <td>: {data?.invoiceData?.postalCode}</td>
                        </tr>
                      </tbody></table>
                  </div>
                </div>
              </div>
              <div className="well">
                <h2>Order Details</h2>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.purchaseItems?
                        data.purchaseItems.map((v, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <p>{v.name}</p>
                              </td>
                              <td>
                                <h5>x {v.quantity}</h5>
                              </td>
                              <td>
                                <p>
                                  {
                                  v.unit_amount.currency_code === "USD"?
                                  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.unit_amount.value*v.quantity)
                                  :
                                  new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.unit_amount.value*v.quantity)
                                  }
                                </p>
                              </td>
                            </tr>
                          )
                        })
                      :null}
                      <tr>
                        <td>
                          <h4>Subtotal</h4>
                        </td>
                        <td>
                        </td>
                        <td>
                          <p>
                            {
                            data.currency === "USD"?
                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.totalItemsPaidAmount)
                            :
                            new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(data.totalItemsPaidAmount)
                            }
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Shipping</h4>
                        </td>
                        <td>
                        </td>
                        <td>
                          <p>
                            {
                            data.currency === "USD"?
                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.shippingPaidAmount)
                            :
                            new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(data.shippingPaidAmount)
                            }
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Total</h4>
                        </td>
                        <td>
                        </td>
                        <td>
                          <h4>
                            {
                            data.currency === "USD"?
                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.totalPaidAmount)
                            :
                            new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(data.totalPaidAmount)
                            }
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}
 
export default Summary;