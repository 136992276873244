import React, { useLayoutEffect, useState  } from "react";
import { withRouter } from 'react-router'
import sumBy from "lodash/sumBy"
import isEmpty from "lodash/isEmpty"
import cloneDeep from "lodash/cloneDeep"
import axios from "axios";
import moment from 'moment';
import env from "../../env.js"

import { 
  ListGroup, 
  ListGroupItem, 
  Media,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import Spinners from "../Extra/Spinners.js"

import OffSet from "../../assets/images/offsite.svg"
import PaymentMethod from "../../assets/images/payment.png"

import client from "../../feathers.js"

var upsCodes = require('ups-service-codes');

const Payment = (props) => {
  const [ spin, setSpin ] = useState(false)
  
  useLayoutEffect (() => {
    let items = props.carts.map(v => {
      return {
        name: v.name,
        unit_amount: {
          value: (props.currency === "USD"?
            Number((v.perUnitAmount+(!isEmpty(v.variations)?sumBy(v.variations, "amount"):0)).toFixed(2).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
            :
            Number(((v.perUnitAmount+(!isEmpty(v.variations)?sumBy(v.variations, "amount"):0))*props.rateUSDtoMYR).toFixed(2).toLocaleString("ms-MY", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
          ),
          currency_code: props.currency,
        },
        quantity: v.quantity,
        description: v.name
        // (!isEmpty(v.variations)?v.variations.map(v1=>{
        //   return v1.type + "-" + v1.value + "-" + (props.currency) + "" + (props.currency === "USD"? v1.totalAmount: v1.totalAmount*props.rateUSDtoMYR)
        // }):[]).toString()
      }
    })
    let totalItemsAmount = (props.currency === "USD"? 
      Number((sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount")))).toFixed(2).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
    :
      Number(((sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount"))))*props.rateUSDtoMYR).toFixed(2).toLocaleString("ms-MY", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
    )

    let totalAmount = (props.currency === "USD"?
      Number((totalItemsAmount+props.shippingFee).toFixed(2).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
    :
      Number((totalItemsAmount+props.shippingFee).toFixed(2).toLocaleString("ms-MY", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
    )

    // convert to MYR 
    // price * rateUSDtoMYR
    let clonePropsCart = cloneDeep(props.carts)
    
    // if MYR
    if(props.currency !== "USD"){
      clonePropsCart.map(v => {
        v.totalNetAmount = Number((v.totalNetAmount*props.rateUSDtoMYR).toFixed(2).toLocaleString("ms-MY", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
        v.perUnitAmount = Number((v.perUnitAmount*props.rateUSDtoMYR).toFixed(2).toLocaleString("ms-MY", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
        if(!isEmpty(v.variations)){
          v.variations.map(v1 => {
            v1.amount = Number((v1.amount*props.rateUSDtoMYR).toFixed(2).toLocaleString("ms-MY", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
            v1.totalAmount = Number((v1.totalAmount*props.rateUSDtoMYR).toFixed(2).toLocaleString("ms-MY", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
            return v1
          })
        }
        return v
      })
    }

    // change ups service code
    if(props.formValue.shippingCompany === 'ups'){
      props.formValue.serviceCode = upsCodes[props.formValue.serviceCode]
    }

    if(spin === false){
      const script = document.createElement("script")
      script.src = `https://www.paypal.com/sdk/js?client-id=${env.paypalClientID}&currency=${props.currency}`
      script.addEventListener("load", () => handleLoaded(
        props.currency, 
        props.rateUSDtoMYR,
        items, 
        totalItemsAmount, 
        totalAmount, 
        props.shippingFee, 
        clonePropsCart, 
        props.formValue,
        props.clearCarts,
        props.history,
        props.userId,
        props.companyInfo,
        props.handleOpen,
        props.voucher
      ))
        document.body.appendChild(script)
    }
  }, [
    props.currency, 
    props.carts, 
    props.rateUSDtoMYR, 
    props.shippingFee,
    props.formValue, 
    props.clearCarts,
    props.history,
    props.userId,
    props.companyInfo,
    props.handleOpen,
    spin,
    props.voucher
  ])

  const handleLoaded = (
    currency,
    rateUSDtoMYR, 
    items, 
    totalItemsAmount,
    totalAmount,  
    shippingAmount, 
    carts, 
    formValue, 
    clearCarts,
    history,
    userId,
    companyInfo,
    handleOpen,
    voucher
  ) => {
      
    window.paypal.Buttons({

      // Set up the transaction
      createOrder: function (data, actions) {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: totalAmount,
              currency_code: currency,
              breakdown: {
                item_total: {
                  value: totalItemsAmount,
                  currency_code: currency,
                },
                shipping: {
                  currency_code: currency,
                  value: shippingAmount
                }
              }
            },
            items: items
          }]
        });
      },

      // Finalize the transaction
      onApprove: function (data, actions) {
        return actions.order.capture().then(function (details) {
          // console.log(details)
          setSpin(true)
          handleOpen(true, "success", "Thank you. Your order has been received.")
          client.service('invoices').create({
            email : formValue.email,
            firstName : formValue.firstName,
            lastName : formValue.lastName,
            address : formValue.address,
            country : formValue.country,
            state : formValue.state,
            postalCode : formValue.postalCode,
            phoneNumber : formValue.phoneNumber,
            countryCode : formValue.countryCode,
            stateOrProvinceCode : formValue.stateOrProvinceCode,

            serviceCode : formValue.serviceCode,
            shippingCompany : formValue.shippingCompany,
            
            rateUSDtoMYR: rateUSDtoMYR,
            currency : currency,
            purchaseItems : carts,
            shippingDiscPercent : formValue.shippingDiscPercent,
            shippingDiscAmount: formValue.shippingDiscAmount,
            shippingAmount : shippingAmount,
            totalItemsAmount : totalItemsAmount,
            totalAmount : totalAmount,

            voucher: voucher,

            userId: userId
          })
          .then(res => {

            let shippingService = res.countryCode === 'MY'?
                "Local Express":  
                res.shippingCompany + "  Express"
            let shippingMethod = res.countryCode === 'MY'?
                "-":  
                res.serviceCode
                
            client.service('transactions').create({
              paymentId : details.id,
              paymentDate : details.create_time,
              paymentStatus : details.status,
              payerId : details.payer.payer_id,
              payerEmail : details.payer.email_address,
              payerName : details.purchase_units[0].shipping.name.full_name,
              billingAddress : details.purchase_units[0].shipping.address,
              
              shippingService: shippingService,
              shippingMethod: shippingMethod,
              
              payeeEmail : details.purchase_units[0].payee.email_address,
              payeeMerchantId : details.purchase_units[0].payee.merchant_id,
              currency : details.purchase_units[0].amount.currency_code,
              shippingPaidAmount : details.purchase_units[0].amount.breakdown.shipping.value,
              totalItemsPaidAmount : details.purchase_units[0].amount.breakdown.item_total.value,
              totalPaidAmount : details.purchase_units[0].amount.value,
              description : details.purchase_units[0].description,
              purchaseItems : details.purchase_units[0].items,
              invoiceId : res._id,

              userId: userId
            })
            .then(res1 => {
              let invs = res
              let trans = res1
              let shipTo = {
                address: invs.address,
                postalCode: invs.postalCode,
                state: invs.state,
                phoneNumber: invs.phoneNumber,
                firstName: invs.firstName,
                lastName: invs.lastName,
                email: invs.email,
                country: invs.country,
              }
              var pattern = "0000";
              let details = {
                createdAt: moment(invs.createdAt).format("MMM Do YYYY"),
                currency: invs.currency,
                invoiceNumber: invs.invoicePrefix + " " + (pattern + invs.invoiceNumber).slice(-4),
                purchaseItems: invs.purchaseItems,
                shippingAmount: invs.shippingAmount,
                totalAmount: invs.totalAmount,
                totalItemsAmount: invs.totalItemsAmount,
                shippingService: shippingService,
                shippingMethod: shippingMethod,
              }
              let billTo = {
                address_line_1: trans.billingAddress.address_line_1,
                address_line_2: trans.billingAddress.address_line_2,
                admin_area_1: trans.billingAddress.admin_area_1,
                admin_area_2: trans.billingAddress.admin_area_2,
                postal_code: trans.billingAddress.postal_code,
                payerEmail: trans.payerEmail,
              }
              
              invs.shippingService = shippingService
              invs.shippingMethod = shippingMethod

                // let companyInfo = companyInfo
                axios.post(`${client.io.io.uri}v2/downloadPdf`, {
                  params: {
                    companyInfo: companyInfo, 
                    details: invs,
                    fileName: 'purchase_invoice.pdf'
                  },
                })
                .then(()=>{
                  axios.post(`${client.io.io.uri}sendInvoice`,{
                    params: {companyInfo, shipTo, billTo, details, mailTo: env.mailTo.concat(formValue.email)},
                  })
                .then((resInv) => {
                  history.push('/summary/'+res1._id)
                  clearCarts()  
                })
              })
              
            })
            .catch(err =>{
              handleOpen(true, "danger", err.message)
              
              client.service('write-logs').create({
                table: 'transactions',
                invoiceId : res._id,
                description: err.message,

                userId: userId,

                // transaction
                // payee & payer - PayPal
                paymentId : details.id,
                paymentDate : details.create_time,
                paymentStatus : details.status,
                payerId : details.payer.payer_id,
                payerEmail : details.payer.email_address,
                payerName : details.purchase_units[0].shipping.name.full_name,
                billingAddress : details.purchase_units[0].shipping.address,

                voucher: voucher,
                
                payeeEmail : details.purchase_units[0].payee.email_address,
                payeeMerchantId : details.purchase_units[0].payee.merchant_id,
                currency : details.purchase_units[0].amount.currency_code,
                payPalPurchaseItems : details.purchase_units[0].items,
              })
            })

          })
          .catch(err =>{
            handleOpen(true, "danger", err.message)
            
            client.service('write-logs').create({
              email : formValue.email,
              firstName : formValue.firstName,
              lastName : formValue.lastName,
              address : formValue.address,
              country : formValue.country,
              state : formValue.state,
              postalCode : formValue.postalCode,
              phoneNumber : formValue.phoneNumber,
              countryCode : formValue.countryCode,
              stateOrProvinceCode : formValue.stateOrProvinceCode,
              
              serviceCode : formValue.serviceCode,
              shippingCompany : formValue.shippingCompany,
              
              currency : currency,
              purchaseItems : carts,
              shippingDiscPercent : formValue.shippingDiscPercent,
              shippingDiscAmount : formValue.shippingDiscAmount,
              shippingAmount : shippingAmount,
              totalItemsAmount : totalItemsAmount,
              totalAmount : totalAmount,
      
              table: 'invoices',
              description: err.message,

              userId: userId,

              voucher: voucher,

              // transaction
              // payee & payer - PayPal
              paymentId : details.id,
              paymentDate : details.create_time,
              paymentStatus : details.status,
              payerId : details.payer.payer_id,
              payerEmail : details.payer.email_address,
              payerName : details.purchase_units[0].shipping.name.full_name,
              billingAddress : details.purchase_units[0].shipping.address,
              
              payeeEmail : details.purchase_units[0].payee.email_address,
              payeeMerchantId : details.purchase_units[0].payee.merchant_id,
              payPalPurchaseItems : details.purchase_units[0].items,

            })
          })
        });
      },
      // onCancel: function (data) {
      //   // Show a cancel page, or return to cart
      //   console.log(data)
      // },
      onError: function (err) {
        // For example, redirect to a specific error page
        // window.location.href = "/your-error-page-here";
        handleOpen(true, "danger", ((err).toString()).substr(0, 100) + "...")
        setSpin(false)
        console.log(err)
      }
    }).render('#paypal-button-container');
  }

  return (  
    <>
      <ul className="mb-3">
        <li className="d-inline-block"><a href="/viewcart">
            Cart
          </a>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}} onClick={() => props.setActiveIndex(0)}>Shipping Address</span>  
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}} onClick={() => props.setActiveIndex(1)}>Shipping Amount</span>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}} onClick={() => props.setActiveIndex(2)}>Information</span>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span className="font-weight-bold">Payment</span>
        </li>
      </ul>
      <div className="mt-4">
        <h3>Infomation</h3>
        <ListGroup>
          <ListGroupItem className="justify-content-between">
            <Media>
              <Media body>
                Email
              </Media>
              <Media body className="text-right">
                <span className="font-weight-bold">{props.formValue.email}</span>
              </Media>
            </Media>
          </ListGroupItem>
          <ListGroupItem className="justify-content-between">
            <Media>
              <Media body>
                Name
              </Media>
              <Media body className="text-right">
                <span className="font-weight-bold">{props.formValue.firstName + " " + props.formValue.lastName}</span>
              </Media>
            </Media>
          </ListGroupItem>
          <ListGroupItem className="justify-content-between">
            <Media>
              <Media body>
                Contact
              </Media>
              <Media body className="text-right">
                <span className="font-weight-bold">{props.formValue.phoneNumber}</span>
              </Media>
            </Media>
          </ListGroupItem>
          <ListGroupItem className="justify-content-between">
            <Media>
              <Media body>
                Ship To
              </Media>
              <Media body className="text-right">
                <span className="font-weight-bold">{props.formValue.address}</span>
              </Media>
            </Media>
          </ListGroupItem>
          <ListGroupItem className="justify-content-between">
            <Media>
              <Media body>
                Country
              </Media>
              <Media body className="text-right">
                <span className="font-weight-bold">{props.formValue.country}</span>
              </Media>
            </Media>
          </ListGroupItem>
        </ListGroup>
      </div>
      <div className="mt-4">
        <h3>Payment</h3>
        <p>All transactions are secure and encrypted.</p>
        <Card>
          <CardBody className="py-2">
            <Media>
              <Media body className="align-self-center">
                Please select payment method
              </Media>
              <Media body className="text-right">
                <img src={PaymentMethod} alt="pracworks carbon" />
              </Media>
            </Media>
          </CardBody>
          <CardHeader className="text-center">
            <img className="my-3" src={OffSet} alt="pracworks"></img>
            <p>
              All Information are encrypted and sent through secured and protected channels.
            </p>
          </CardHeader>
        </Card>
      </div>
      <div className="mt-4">
        <div id="paypal-button-container" className="m-auto" style={{width: 300}}></div>
      </div>
      <Spinners 
        spinning={spin}
        content={
          <>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </>
        } />
    </>
  );
}
 
export default withRouter(Payment);