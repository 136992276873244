import {
  FETCH_COMPANY,
 } from '../actions/companyActions';

const INITIAL_STATE = {
  data: {
    rateUSDtoMYR: "4.6",
    companyName: "PRACWORKS CARBON SDN. BHD.",
    companyNumber: "(1572047P)",
    address: "12, JALAN HITECH 6/1, KAWASAN PERINDUSTRIAN HI-TECH 6, 43500 SEMENYIH, SELANGOR, MALAYSIA",
    state: "Selangor",
    country: "Malaysia",
    postalCode: "40150",
    phoneNumber: "+60163950893",
    countryCode: "MY",
    personName: "Yun Kit",
    stateOrProvinceCode: "10"
  },
}

export default function companyReducer(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_COMPANY:
      return {
        ...state,
        data:action.data,
      }
    default:
      return state

  }
}